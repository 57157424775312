import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>

    );
}

function Event() {
    const { i18n, t } = useTranslation();
    const event = [
        {
            date: '16 July, 2024',
            title: t('event-title-9'),
            link: '/exciting-collaboration',
            img: 'assets/img/event/Porthmouth1.png',
        },
        {
            date: '26 June, 2024',
            title: t('event-title-8'),
            link: '/collaboration-ringo',
            img: 'assets/img/event/ringo-2.jpg',
        },
        {
            date: '24 May, 2024',
            title: t('event-title-1'),
            link: '/ACCA-hamkorlik-memorandumi',
            img: 'assets/img/event/acca-event.jpg',
        },
        {
            date: '12 March, 2024',
            title: t('event-title-2'),
            link: '/Uyushma-azolari-safi-kengaymoqda',
            img: 'assets/img/event/event88.jpg',
        },
        {
            date: '1 March, 2024',
            title: t('event-title-3'),
            link: '/Rasul-Kusherbayev-Yaponiya-elchisi-Hatori-Takashi-bilan-uchrashdi',
            img: 'assets/img/event/event77.jpg',
        },
        {
            date: '17 October, 2023',
            title: t('event-title-4'),
            link: '/MU-University-is-signing-new-contracts-with-Japanese-companies',
            img: 'assets/img/event/event1.jpg',
        },
        {
            date: '27 July, 2023',
            title: t('event-title-5'),
            link: '/MU-University-is-launching-cooperation-with-Dongseo-University',
            img: 'assets/img/event/event2.jpg',
        },
        {
            date: '7 April, 2023',
            title: t('event-title-6'),
            link: '/StudentHunter-towards-expanding-partnerships',
            img: 'assets/img/event/event4.jpg',
        },
        {
            date: '30 March, 2023',
            title: t('event-title-7'),
            link: '/Millat-Umidi-University-students-visited-Artel',
            img: 'assets/img/event/event6.jpg',
        },
        {
            date: '20 May, 2022',
            title: 'Cambridge Coding Festival.',
            link: '/Cambridge-coding-festival',
            img: 'assets/img/event/coding-festival.jpg',
        }
    ];

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="event pt-0 pb-90 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-relative">
                            <div className="section-title center-align mb-50 text-left wow fadeInDown animated"
                                 data-animation="fadeInDown"
                                 data-delay=".4s">
                                <h5>
                                    <i className="fal fa-graduation-cap" /> {t('Our events')}
                                </h5>
                                <h2>{t('Upcoming Events')}</h2>
                            </div>
                        </div>
                    </div>
                    <Slider className="row class-active" {...settings}>
                        {event.map((event, index) => (
                            <div key={index} className="col-lg-4 col-md-6 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                <div className="event-item mb-30 event-hover-zoomin">
                                    <div className="thumb">
                                        <Link to={event.link}>
                                            <img src={event.img} className='event-img' style={{objectFit: "cover"}} alt="" />
                                        </Link>
                                    </div>
                                    <div className="event-content">
                                        <div className="date">
                                            <strong>{event.date.split(' ')[0]}</strong> {event.date.split(' ')[1]} {event.date.split(' ')[2]}, {event.date.split(' ')[3]}
                                        </div>
                                        <h4>
                                            <Link to={event.link}>{event.title}</Link>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Event;