import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function First() {
    const { t } = useTranslation();

    const events = [
        {
            date: '16 July, 2024',
            title: t('event-title-9'),
            link: '/exciting-collaboration',
            imgSrc: 'assets/img/event/Porthmouth1.png',
        },
        {
            date: '26 June, 2024',
            title: t('event-title-8'),
            link: '/collaboration-ringo',
            imgSrc: 'assets/img/event/ringo-2.jpg',
        },
        {
            date: '24 May, 2024',
            title: t('event-title-1'),
            link: '/ACCA-hamkorlik-memorandumi',
            imgSrc: 'assets/img/event/acca-event.jpg',
        },
        {
            date: '12 March, 2024',
            title: t('event-title-2'),
            link: '/Uyushma-azolari-safi-kengaymoqda',
            imgSrc: 'assets/img/event/event88.jpg',
        },
        {
            date: '1 March, 2024',
            title: t('event-title-3'),
            link: '/Rasul-Kusherbayev-Yaponiya-elchisi-Hatori-Takashi-bilan-uchrashdi',
            imgSrc: 'assets/img/event/event77.jpg',
        },
        {
            date: '17 October, 2023',
            title: t('event-title-4'),
            link: '/MU-University-is-signing-new-contracts-with-Japanese-companies',
            imgSrc: 'assets/img/event/event1.jpg',
        },
        {
            date: '27 July, 2023',
            title: t('event-title-5'),
            link: '/MU-University-is-launching-cooperation-with-Dongseo-University',
            imgSrc: 'assets/img/event/event2.jpg',
        },
        {
            date: '7 April, 2023',
            title: t('event-title-6'),
            link: '/StudentHunter-towards-expanding-partnerships',
            imgSrc: 'assets/img/event/event4.jpg',
        },
        {
            date: '30 March, 2023',
            title: t('event-title-7'),
            link: '/Millat-Umidi-University-students-visited-Artel',
            imgSrc: 'assets/img/event/event6.jpg',
        },
        {
            date: '20 May, 2022',
            title: 'Cambridge Coding Festival.',
            link: '/Cambridge-coding-festival',
            imgSrc: 'assets/img/event/coding-festival.jpg',
        }
    ];

    return (
        <section className="shop-area pt-90 pb-120 p-relative">
            <div className="container">
                <div className="row">
                    {events.map((event, index) => (
                        <div className="col-lg-4 col-md-6 mb-4" key={index}>
                            <div className="card h-100 d-flex flex-column">
                                <img style={{maxWidth: "100%", height: "350px", objectFit: "cover"}} src={event.imgSrc} className="card-img-top" alt="Event"/>
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <div>
                                        <h5 className="card-title">{event.title}</h5>
                                        <p className="card-text">{event.date}</p>
                                    </div>
                                    <Link style={{padding: "15px 20px", fontSize: "18px", width: "50%"}} to={event.link} className="mt-2 btn btn-primary">{t('Read More')}</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default First;
